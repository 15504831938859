<template>
  <!-- BEGIN: Add -->
  <b-card v-if="type === 'add'">
    <validation-observer ref="simpleRules">
      <b-form
        ref="form"
        @submit="onSubmit"
        @reset="onReset"
      >
        <h3>เพิ่มบัญชี</h3>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ชื่อบัญชี"
              label-for="ชื่อบัญชี"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="accName"
                rules="required"
              >
                <b-form-input
                  id="accName"
                  v-model="form.accName"
                  placeholder="ชื่อบัญชี"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ธนาคาร"
              label-cols="3"
            >
              <v-select
                v-model="form.bank"
                :clearable="false"
                :options="bankOptions"
                :reduce="option => option.code"
              >
                <template v-slot:option="option">
                  <img :src="require(`@/assets/images/bank/${option.img}`)" width="30" style="margin-right:10px">
                  {{ option.label }}
                </template>
                <template v-slot:selected-option="option">
                  <img :src="require(`@/assets/images/bank/${option.img}`)" width="30" style="margin-right:10px">
                  {{ option.label }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="เลขบัญชี"
              label-for="เลขบัญชี"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="accNo"
                rules="required"
              >
                <b-form-input
                  id="accNo"
                  v-model="form.accNo"
                  placeholder="เลขบัญชี"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="บัญชีผู้ใช้"
              label-for="บัญชีผู้ใช้"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="form.username"
                  placeholder="บัญชีผู้ใช้"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="รหัสผ่าน"
              label-for="รหัสผ่าน"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-form-input
                  id="password"
                  v-model="form.password"
                  placeholder="รหัสผ่าน"
                  type="password"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button

              type="submit"
              variant="success"
              :disabled="btnLoading"
            >
              <span v-if="btnLoading"><b-spinner small />กำลังบันทึก</span>
              <span v-else>บันทึก</span>
            </b-button>
            <b-button

              type="reset"
              variant="danger"
              style="margin-left: 1%"
              :disabled="btnLoading"
            >
              ล้างค่า
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
  <!-- END: Add -->

  <!-- BEGIN: Edit -->
  <b-card v-else-if="type === 'edit'">
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <validation-observer
      v-else
      ref="simpleRules"
    >
      <b-form
        ref="form"
        @submit="onEdit"
        @reset="onReset"
      >
        <h3>เพิ่มบัญชี</h3>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ชื่อบัญชี"
              label-for="ชื่อบัญชี"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="accName"
                rules="required"
              >
                <b-form-input
                  id="accName"
                  v-model="initialValue.accName"
                  placeholder="ชื่อบัญชี"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ธนาคาร"
              label-cols="3"
            >
              <v-select
                v-model="initialValue.bank"
                :clearable="false"
                :options="bankOptions"
                :reduce="option => option.code"
              >
                <template v-slot:option="option">
                  <img :src="require(`@/assets/images/bank/${option.img}`)" width="30" style="margin-right:10px">
                  {{ option.label }}
                </template>
                <template v-slot:selected-option="option">
                  <img :src="require(`@/assets/images/bank/${option.img}`)" width="30" style="margin-right:10px">
                  {{ option.label }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="เลขบัญชี"
              label-for="เลขบัญชี"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="accNo"
                rules="required"
              >
                <b-form-input
                  id="accNo"
                  v-model="initialValue.accNo"
                  placeholder="เลขบัญชี"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="บัญชีผู้ใช้"
              label-for="บัญชีผู้ใช้"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="initialValue.username"
                  placeholder="บัญชีผู้ใช้"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="รหัสผ่าน"
              label-for="รหัสผ่าน"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-form-input
                  id="password"
                  v-model="initialValue.password"
                  placeholder="รหัสผ่าน"
                  type="password"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button

              type="submit"
              variant="success"
              :disabled="btnLoading"
            >
              <span v-if="btnLoading"><b-spinner small />กำลังบันทึก</span>
              <span v-else>บันทึก</span>
            </b-button>
            <b-button

              type="reset"
              variant="danger"
              style="margin-left: 1%"
              :disabled="btnLoading"
            >
              ล้างค่า
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
  <!-- END: Edit -->

  <!-- BEGIN: Detail -->
  <b-card v-else-if="type === 'detail'">
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <validation-observer
      v-else
      ref="simpleRules"
    >
      <b-form
        ref="form"
        @submit="onSubmit"
        @reset="onReset"
      >
        <h3>เพิ่มบัญชี</h3>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ชื่อบัญชี"
              label-for="ชื่อบัญชี"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="accName"
                rules="required"
              >
                <b-form-input
                  id="accName"
                  v-model="initialValue.accName"
                  placeholder="ชื่อบัญชี"
                  disabled
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="ธนาคาร"
              label-cols="3"
            >
              <v-select
                v-model="initialValue.bank"
                :clearable="false"
                :options="bankOptions"
                :reduce="option => option.code"
                disabled
              >
                <template v-slot:option="option">
                  <img :src="require(`@/assets/images/bank/${option.img}`)" width="30" style="margin-right:10px">
                  {{ option.label }}
                </template>
                <template v-slot:selected-option="option">
                  <img :src="require(`@/assets/images/bank/${option.img}`)" width="30" style="margin-right:10px">
                  {{ option.label }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="เลขบัญชี"
              label-for="เลขบัญชี"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="accNo"
                rules="required"
              >
                <b-form-input
                  id="accNo"
                  v-model="initialValue.accNo"
                  placeholder="เลขบัญชี"
                  disabled
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="บัญชีผู้ใช้"
              label-for="บัญชีผู้ใช้"
              label-cols="3"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="initialValue.username"
                  placeholder="บัญชีผู้ใช้"
                  disabled
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  กรุณาใส่ข้อมูล
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
  <!-- END: Detail -->
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormInput,
  BButton,
  BFormGroup,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // General
    BRow,
    BCol,
    BCard,
    BForm,
    BFormInput,
    BButton,
    BFormGroup,
    BSpinner,

    ValidationProvider,
    ValidationObserver,

    ToastificationContent,
    vSelect,
  },
  props: {
    type: String,
  },
  data() {
    return {
      form: {
        accName: '',
        accNo: '',
        username: '',
        password: '',
        bank: null,
      },
      bankOptions: [{ label: 'KBANK', code: 'kbank', img: 'kbank.png' }, { label: 'SCB', code: 'scb', img: 'scb.png' }],
      loading: false,
      btnLoading: false,
      required,
      min,
      initialValue: {},
    }
  },
  created() {
    if (this.type === 'edit' || this.type === 'detail') {
      this.fetchData(this.$route.params.id)
    }
  },
  methods: {
    onSubmit(e) {
      this.btnLoading = true
      this.$refs.simpleRules.validate().then(success => {
        e.preventDefault()
        if (success) {
          e.preventDefault()
          const payload = {
            accName: this.form && this.form.accName ? this.form.accName.trim() : '',
            bank: this.form && this.form.bank ? this.form.bank.trim() : '',
            accNo: this.form && this.form.accNo ? this.form.accNo.trim() : '',
            username: this.form && this.form.username ? this.form.username.trim() : '',
            password: this.form && this.form.password ? new Buffer.from(this.form.password.trim()).toString('base64') : '',
          }
          useJwt
            .post('/account-msts', payload)
            .then(res => {
              console.log('จาก api', res)
              if (res.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'สำเร็จ',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'บันทึกข้อมูลสำเร็จ',
                  },
                })
              } else {
                throw res.data.message
              }
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'เกิดข้อผิดพลาด',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: `${err}`,
                },
              })
            })
            .finally(() => {
              this.btnLoading = false
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'เกิดข้อผิดพลาด',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            },
          })
          e.preventDefault()
          this.btnLoading = false
        }
      })
    },
    onEdit(e) {
      this.btnLoading = true
      this.$refs.simpleRules.validate().then(success => {
        e.preventDefault()
        if (success) {
          e.preventDefault()
          const payload = {
            accName: this.initialValue && this.initialValue.accName ? this.initialValue.accName.trim() : '',
            accNo: this.initialValue && this.initialValue.accNo ? this.initialValue.accNo.trim() : '',
            bank: this.initialValue && this.initialValue.bank ? this.initialValue.bank.trim() : '',
            username: this.initialValue && this.initialValue.username ? this.initialValue.username.trim() : '',
            password: this.initialValue && this.initialValue.password ? new Buffer.from(this.initialValue.password.trim()).toString('base64') : '',
          }
          useJwt
            .update('/account-msts', this.$route.params.id, payload)
            .then(res => {
              console.log('จาก api', res)
              if (res.status === 200) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'สำเร็จ',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'บันทึกข้อมูลสำเร็จ',
                  },
                })
              } else {
                throw res.data.message
              }
            })
            .catch(err => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'เกิดข้อผิดพลาด',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: `${err}`,
                },
              })
            })
            .finally(() => {
              this.btnLoading = false
            })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'เกิดข้อผิดพลาด',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            },
          })
          e.preventDefault()
          this.btnLoading = false
        }
      })
    },
    onReset(e) {
      e.preventDefault()
      this.form = {
        accName: '',
        accNo: '',
        username: '',
        password: '',
        bank: ''
      }
      this.initialValue = {
        accName: '',
        accNo: '',
        username: '',
        password: '',
        bank: ''
      }
    },
    fetchData(id) {
      this.loading = true
      useJwt
        .get('/account-msts', id)
        .then(res => {
          console.log('res fetch', res)
          if (res.status === 200 && res.data) {
            this.initialValue = {
              accName: res.data.accName ? res.data.accName : '',
              accNo: res.data.accNo ? res.data.accNo : '',
              username: res.data.username ? res.data.username : '',
              bank: res.data.bank ? res.data.bank : '',
            }
          } else {
            throw res.data.message
          }
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'เกิดข้อผิดพลาดในการแสดงข้อมูล',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: `${err}`,
            },
          })
        })
        .finally(() => {
          console.log('initialValue', this.initialValue)
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
